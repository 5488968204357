import {
  GET_DOCUMENTS_ATTEMPT,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  CLEAR_DOCUMENTS_STATE,
  GET_DOCUMENT_TYPES_ATTEMPT,
  GET_DOCUMENT_TYPES_FAILURE,
  GET_DOCUMENT_TYPES_SUCCESS,
  CLEAR_DOCUMENT_TYPES_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const initialState = {
  documents: null,
  documentTypes: null,
  attempting: false,
  attemptingDT: false,
  errorCode: null,
};

export const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        documents: null,
        attempting: true,
        errorCode: null,
      };
    case GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        documents: null,
        errorCode: action.errorCode,
        attempting: false,
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        errorCode: null,
        attempting: false,
      };
    case CLEAR_DOCUMENTS_STATE:
      return {
        initialState,
      };
    case GET_DOCUMENT_TYPES_ATTEMPT:
      return {
         ...state,
         documentTypes: null,
         attemptingDT: true,
         errorCode: null,
      };
    case GET_DOCUMENT_TYPES_FAILURE:
      return {
        ...state,
        documentTypes: null,
        attemptingDT: false,
        errorCode: action.errorCodeDT,
      };
    case GET_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentTypes: action.documentTypes,
        errorCode: null,
        attemptingDT: false,
      };
    case CLEAR_DOCUMENT_TYPES_STATE:
      return {
        initialState,
      };
    case CLEAR_STATE:
    return {
      initialState,
    };
    default:
      return state;
  }
};
