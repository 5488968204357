import { createSelector } from 'reselect';

const getDocuments = (state) => state.documents.documents;
const getDocumentsAttempting = (state) => state.documents.attempting;
const getDocumentsError = (state) => state.documents.errorCode;

export const getDocumentsSelector = createSelector(getDocuments, (documents) => documents);
export const getDocumentsAttemptingSelector = createSelector(getDocumentsAttempting, (attempting) => attempting);
export const getDocumentsErrorSelector = createSelector(getDocumentsError, (errorCode) => errorCode);

const getDocumentTypes = (state) => state.documents.documentTypes;
const getDocumentTypesAttempting = (state) => state.documents.attemptingDT;
const getDocumentTypesError = (state) => state.documents.errorCode;

export const getDocumentTypesSelector = createSelector(getDocumentTypes, (documentTypes) => documentTypes);
export const getDocumentTypesAttemptingSelector = createSelector(getDocumentTypesAttempting, (attemptingDT) => attemptingDT);
export const getDocumentTypesErrorSelector = createSelector(getDocumentTypesError, (errorCode) => errorCode);
