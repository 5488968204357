import React from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Text = (props) => {
  const { text, classes, variant, style, extraText, handleClick, extraClasses, onClick, tx, afterText, afterClasses } = props;
  const { t } = useTranslation();

  return (
    <Typography variant={variant} className={classes} style={style} onClick={onClick}>
      {text ? <span>{text}</span> : <span>{t(tx)}</span>}
      {afterText ? <span> <span className={afterClasses}>{afterText}</span></span> : null}
      {extraText ? (
        <span onClick={handleClick} className={extraClasses}>
          {extraText}
        </span>
      ) : null}
    </Typography>
  );
};

export default Text;
