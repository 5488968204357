import { call, all, put, takeLatest } from 'redux-saga/effects';
import { getDataFromService } from '../../services/apiGateway';
import { getDocumentsSuccess, getDocumentsFailure, getDocumentTypesSuccess, getDocumentTypesFailure } from './actions';
import { GET_DOCUMENTS_ATTEMPT, GET_DOCUMENT_TYPES_ATTEMPT } from './actionTypes';

function* getDocumentsSage() {
  try {
    const documents = yield call(getDataFromService, '/documents');
    yield put(getDocumentsSuccess(documents?.data.filter((d) => d.type !== "portfolio_invoice" && d.type !== "invoice" && (!d.missingDocument || d.templateDocument || d.isPending))));
  } catch (errorCode) {
    yield put(getDocumentsFailure(errorCode.response || errorCode));
  }
}

function* getDocumentTypes() {
  try {
    const documentTypes = yield call(getDataFromService, '/documents/document-types');
    yield put(getDocumentTypesSuccess(documentTypes?.data));
  } catch (errorCode) {
    yield put(getDocumentTypesFailure(errorCode.response || errorCode));
  }
}

function* documentsSaga() {
  yield all([takeLatest(GET_DOCUMENTS_ATTEMPT, getDocumentsSage)]);
  yield all([takeLatest(GET_DOCUMENT_TYPES_ATTEMPT, getDocumentTypes)]);  
}

export default documentsSaga;
