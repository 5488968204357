import {
  GET_DOCUMENTS_ATTEMPT,
  GET_DOCUMENTS_FAILURE,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENT_TYPES_ATTEMPT,
  GET_DOCUMENT_TYPES_FAILURE,
  GET_DOCUMENT_TYPES_SUCCESS,
  CLEAR_DOCUMENTS_STATE,
  CLEAR_DOCUMENT_TYPES_STATE,
  CLEAR_STATE,
} from './actionTypes';

export const getDocumentsAttempt = () => ({
  type: GET_DOCUMENTS_ATTEMPT,
});

export const getDocumentsSuccess = (documents) => ({
  type: GET_DOCUMENTS_SUCCESS,
  documents,
});

export const getDocumentsFailure = (errorCode) => ({
  type: GET_DOCUMENTS_FAILURE,
  errorCode,
});

export const clearDocumentsState = () => ({
  type: CLEAR_DOCUMENTS_STATE,
});

export const getDocumentTypesAttempt = () => ({
  type: GET_DOCUMENT_TYPES_ATTEMPT,
});

export const getDocumentTypesSuccess = (documentTypes) => ({
  type: GET_DOCUMENT_TYPES_SUCCESS,
  documentTypes,
});

export const getDocumentTypesFailure = (errorCodeDT) => ({
  type: GET_DOCUMENT_TYPES_FAILURE,
  errorCodeDT,
});

export const clearDocumentTypesState = () => ({
  type: CLEAR_DOCUMENT_TYPES_STATE,
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
