import React, {useEffect} from 'react';

// mui utils
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableContainer from '@mui/material/TableContainer';
import Container from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';

// third party utils
import { useSelector,useDispatch } from 'react-redux';

import cloudinary from 'cloudinary-core';
import moment from 'moment';

// iangels utils

import {getDocumentTypesAttempt } from './actions';
import { getAuthResultSelector } from '../App/selectors';
import { getDocumentTypesSelector } from './selectors';

import { loadConfig } from '../..//env/config_util';
import Text from '../../components/text';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

const SharedInfo = ({sharedBy, sharedWith, sharedAllWith }) => {
  return (
    <>
    {
      sharedBy || sharedAllWith ? (
        <div style={{ marginBottom: 5 , padding: 15}} className="gray-bg g4-c f-f-g bold f-size-14 ribbon-sector">
        {sharedBy ? 'Shared by' : 'Documents shared with'}: {sharedBy || sharedAllWith}
      </div>
      ) :
      sharedWith ? (
        <div>
          <br className="toPdf hidden" />
          <Text tx="portfolio.shared.sharedWith" afterText={sharedWith} classes="g-c f-size-12"
            afterClasses="f-size-12 underline b-c"/>
        </div>
      ) :
      null
      }
    </>
  );
};

const DocumentsTable = ({ filteredDocuments, matches }) => {
  //hooks
  const isMobile = IsMobileDevice();
  const config = loadConfig();
  const authResult = useSelector(getAuthResultSelector);
  const documentTypesMap = useSelector(getDocumentTypesSelector);
  const dispatch = useDispatch();
  const getUrlByType = (url, groupType, isCloudinary) => {
    if (!url) {
      return;
    }

    if (isCloudinary) {
      return cloudinary.Cloudinary.new({
        cloud_name: config.config_module.cloudinaryCloudName,
      }).url(url);
    }

    return `${url}?access_token=${authResult.__raw}`;
  };

  const viewDocUrl = (fileElement) => {
    const viewLink = !fileElement.missingDocument
      ? fileElement.url && getUrlByType(fileElement.url, fileElement.type, fileElement.isCloudinary)
      : fileElement.templateUrl &&
      getUrlByType(fileElement.templateUrl, fileElement.type, fileElement.templateIsCloudinary);

    fetch(viewLink)
      .then(function (response) {
        return response.blob();
      })
      .then(function (myBlob) {
        var fileURL = URL.createObjectURL(myBlob);
        window.open(fileURL);
      });
  };

  const downloadDocUrl = (fileElement) => {
    const link = document.createElement('a');

    link.href = !fileElement.missingDocument
      ? fileElement.downloadUrl && getUrlByType(fileElement.downloadUrl, fileElement.type, fileElement.isCloudinary)
      : fileElement.templateDownloadUrl &&
      getUrlByType(fileElement.templateDownloadUrl, fileElement.type, fileElement.templateIsCloudinary);

    link.click();
  };

  const isPending = (row) => {
    return row.isPending;
  }

  const getRowDate = (row) => {
    if (!documentTypesMap || !row.dateCreated) return null;
    switch(documentTypesMap?.find((d)=>d.name === row.type)?.periodInDocumentsCenter)
    {
      case 'none':
        return '';
      case 'monthly':
        return moment(row.dateCreated).format('MMM, YYYY');
      case 'quarterly':
          return 'Q' + moment(row.dateCreated).quarter() + ', ' + moment(row.dateCreated).format('YYYY')
      case 'yearly':
        return moment(row.dateCreated).format('YYYY');
      default:
        return moment(row.dateCreated).format('MMM, YYYY');
    }
  };

  useEffect(() => {
    dispatch(getDocumentTypesAttempt());
 }, [dispatch]);

  return (
    <Container
      style={{
        padding: 0,
        marginTop: 40,
        width: '100%',
        minHeight: 800,
      }}
    >
      <Box>
      
      {Object.entries(filteredDocuments).map(([sharedBy, documentsByType], sharedByIndex) => (
      <div key={sharedByIndex} style={{ marginBottom: 25 }}>
        {/* Add header for shared_by */}                        
        {sharedBy !== "null" ? <SharedInfo sharedBy={sharedBy} />: <SharedInfo sharedAllWith={Object.values(documentsByType).flat().find(item => item.sharedAllWith)?.sharedAllWith} />}
        {/* Iterate over each document type within the current shared_by group */}
        {Object.entries(documentsByType).map(([type, documents], typeIndex) => (
          <div style={{ marginBottom: 25 }} key={typeIndex}>
            <TableContainer component={Paper} style={{ borderRadius: 0, borderTop: '#4CBABB solid 3px' }}>
              <div style={{ padding: 15 }}>
                <Text classes="f-f-tgf f-w-500 g4-c f-size-16" text={documentTypesMap?.find((d)=>d.name === type)?.description || type} />
              </div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="g1-bg">
                    <TableCell width="20%" className="f-w-700">
                      Company Name
                      {isMobile && <div>Round Name</div>}
                    </TableCell>
                    {!isMobile && (
                      <TableCell width="20%" className="f-w-700">
                        Round Name
                      </TableCell>
                    )}
                    <TableCell width="20%" className="f-w-700" style={{ verticalAlign: 'top' }}>
                      Entity
                      {isMobile && (
                        <div>
                          <br />
                          Date
                        </div>
                      )}
                    </TableCell>
                    {!isMobile && (
                      <TableCell width="20%" className="f-w-700">
                        Date
                      </TableCell>
                    )}
                    <TableCell width="10%"></TableCell>
                    <TableCell width="10%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((row, rowIndex) => (
                  <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={`f-f-g ${isPending(row) ? ' gr-bg' : ''}`}>
                    <TableCell className="f-f-tgf f-w-500 f-size-12 g4-c">
                        {row.startup}
                        {isMobile && <div className="f-f-g f-w-400 f-size-12">{row.roundName}</div> }
                    </TableCell>
                    {!isMobile && <TableCell className="f-f-g f-w-400 f-size-12">{row.roundName}</TableCell>}
                    <TableCell className="f-f-g f-w-400 f-size-12">
                        {row.entity} {isMobile && <div>{getRowDate(row)}</div>}
                        <SharedInfo sharedWith={row.sharedWith} />
                    </TableCell>
                    {!isMobile && <TableCell className="f-f-g f-w-400 f-size-12">{getRowDate(row)}</TableCell>}
                    <TableCell colspan={isPending(row) ? 2 : null}>
                        {isPending(row) ? `${documentTypesMap?.find((d)=>d.name === type)?.description} not yet uploaded` : <Button
                          variant="text"
                          style={{ color: 'black', minWidth: 10, padding: 0 }}
                          startIcon={<VisibilityIcon className="g-c" />}
                          onClick={() => viewDocUrl(row)}
                        >
                          {!isMobile && <span>VIEW</span>}
                        </Button>}
                      </TableCell>
                      {isPending(row) ? null : <TableCell>
                        <Button
                          variant="text"
                          style={{ color: 'black' }}
                          startIcon={<FileDownloadIcon className="g-c" />}
                          onClick={() => downloadDocUrl(row)}
                        >
                          {!isMobile && <span>DOWNLOAD</span>}
                        </Button>
                      </TableCell>}
                    </TableRow>
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
        </div>
))}
      </Box>
    </Container>
  );
};

export default DocumentsTable;
