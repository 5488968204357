export const GET_DOCUMENTS_ATTEMPT = 'GET_DOCUMENTS_ATTEMPT';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const CLEAR_DOCUMENTS_STATE = 'CLEAR_DOCUMENTS_STATE';

export const GET_DOCUMENT_TYPES_ATTEMPT = 'GET_DOCUMENT_TYPES_ATTEMPT';
export const GET_DOCUMENT_TYPES_FAILURE = 'GET_DOCUMENT_TYPES_FAILURE';
export const GET_DOCUMENT_TYPES_SUCCESS = 'GET_DOCUMENT_TYPES_SUCCESS';
export const CLEAR_DOCUMENT_TYPES_STATE = 'CLEAR_DOCUMENT_TYPES_STATE';

export const CLEAR_STATE = 'CLEAR_STATE';
